<template>
  <div class="profile-container">


    <b-row align-h="center" v-if="is_renew_now_visible">
      <b-col xl="10" lg="12" md="12" sm="12">
        <div class="card card-custom mt-20 py-8 shadow-sm">
          <b-row align-h="center">
            <h4><div class="font-weight-bold">{{$t('MEMBER.RENEW_NOW_INFO')}}</div></h4>
          </b-row>
          <b-row align-h="center">
            <button style="font-size: 22px; min-height: 35px; min-width: 280px;" class="btn btn-success btn-lg mt-4" @click.prevent="renew_now_clicked()">{{$t('MEMBER.RENEW_NOW')}}</button>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <MemberQRComponent
      v-if="member"
      class="mt-8 pt-16"
      :member="member"
      :company="company"
      :instance_id="instance_id"
      :qr_hash="qr_hash"
      @save_data="save_data"
    />

    <MemberAddMembershipsComponent
      v-if="member && company.profile && company.profile.add_membership"
      :member="member"
      :memberships="memberships"
      :companies="companies"
      @created="memberships_created"
    />


    <MemberProfileDetailsComponent
      class="mt-8 pt-16"
      :member="member"
      :company="company"
      :expanded="true"
      @save_data="save_data"
    />

    <MemberContactDetailsComponent
      class="mt-16"
      :member="member"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />

    <MemberAddressDetailsComponent
      v-if="has_any_address_fields_unlocked"
      class="mt-16"
      :member="member"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />

    <MemberInvoiceAddressDetailsComponent
    v-if="has_any_invoice_fields_unlocked"
      class="mt-16"
      :member="member"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />
    
    <MemberTableEvents
      v-if="company.profile && company.profile.events"
      class="mt-16"
      :member="member"
      :items="events"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />

    <MemberTableMemberships
      v-if="company.profile && company.profile.membership"
      class="mt-16"
      :member="member"
      :items="memberships"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />


    <MemberTableOrders
      v-if="company.profile && company.profile.orders"
      class="mt-16"
      :member="member"
      :items="orders"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />

    <MemberTableFiles
      v-if="company.profile && company.profile.files"
      class="mt-16"
      :member="member"
      :items="files"
      :allow_upload="company.profile.allow_upload"
      :company="company"
      :expanded="false"
      @save_data="save_data"
      @created="file_created"
    />

    <MemberTableFamily
      v-if="company.profile && company.profile.family_members"
      class="mt-16"
      :member="member"
      :items="family_members"
      :company="company"
      :expanded="false"
      @save_data="save_data"
      @created="family_member_created"
      @updated="family_member_updated"
    />

    <MemberTableParents
      v-if="company.profile && company.profile.guardians"
      class="mt-16"
      :member="member"
      :items="parents"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />


    <MemberTableEducations
      v-if="company.profile && company.profile.educations"
      class="mt-16"
      :member="member"
      :items="educations"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />

    <MemberTableInvoices
      v-if="company.profile && company.profile.invoices"
      class="mt-16"
      :member="member"
      :items="invoices"
      :company="company"
      :expanded="false"
      @save_data="save_data"
    />


    <b-row class="my-16">
      <b-col></b-col>
    </b-row>

    <div v-if="company && company.company_settings && company.company_settings.exit_approval">
      <Confirm
        :title="$t('MEMBER.REQUEST_EXIT')"
        :message="$t('MEMBER.CONFIRM_REQUEST_EXIT')"
        ref="confirm_request_exit"
        @confirm="confirmed_request_exit"
      />
      
      <b-row v-if="member.status === 'MEMBER' || member.status === 'PENDING_EXIT'">
        <b-col>
          <div class="d-flex align-items-end flex-column mb-8">
            <b-button
              variant="outline-danger"
              @click.prevent="request_exit"
              :disabled="member.status === 'PENDING_EXIT'"
              >{{ member.status === 'PENDING_EXIT' ? $t('MEMBER.PENDING_EXIT') : $t('MEMBER.REQUEST_EXIT') }}</b-button>
            
          </div>
        </b-col>
      </b-row>
    </div>

  </div>

</template>

<style lang="css" scoped>

.profile-container {
  -webkit-box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 64px;
  max-width: 1300px;
  margin: auto;
}


</style>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import MemberProfileDetailsComponent from '@/view/pages/members/loggedin/new_member_profile/MemberProfileDetailsComponent.vue';
import MemberContactDetailsComponent from '@/view/pages/members/loggedin/new_member_profile/MemberContactDetailsComponent.vue';
import MemberAddressDetailsComponent from '@/view/pages/members/loggedin/new_member_profile/MemberAddressDetailsComponent.vue';
import MemberInvoiceAddressDetailsComponent from '@/view/pages/members/loggedin/new_member_profile/MemberInvoiceAddressDetailsComponent.vue';
import MemberTableEvents from '@/view/pages/members/loggedin/new_member_profile/MemberTableEvents.vue';
import MemberTableMemberships from '@/view/pages/members/loggedin/new_member_profile/MemberTableMemberships.vue';
import MemberTableOrders from '@/view/pages/members/loggedin/new_member_profile/MemberTableOrders.vue';
import MemberTableEducations from '@/view/pages/members/loggedin/new_member_profile/MemberTableEducations.vue';
import MemberTableInvoices from '@/view/pages/members/loggedin/new_member_profile/MemberTableInvoices.vue';
import MemberQRComponent from '@/view/pages/members/loggedin/new_member_profile/MemberQRComponent.vue';
import MemberTableFiles from './MemberTableFiles.vue';
import MemberTableFamily from '@/view/pages/members/loggedin/new_member_profile/MemberTableFamily.vue';
import MemberTableParents from '@/view/pages/members/loggedin/new_member_profile/MemberTableParents.vue';
import MemberAddMembershipsComponent from '@/view/pages/members/loggedin/new_member_profile/MemberAddMembershipsComponent.vue';
import Confirm from '@/view/components/Confirm.vue';


export default {

  name: 'MemberProfile',

  props: [],

  mixins: [ toasts ],

  components: {
    Confirm,
    MemberProfileDetailsComponent,
    MemberContactDetailsComponent,
    MemberAddressDetailsComponent,
    MemberInvoiceAddressDetailsComponent,
    MemberTableEvents,
    MemberTableMemberships,
    MemberTableOrders,
    MemberTableEducations,
    MemberTableInvoices,
    MemberTableFiles,
    MemberTableFamily,
    MemberTableParents,
    MemberQRComponent,
    MemberAddMembershipsComponent
  },

  watch: {

  },

  computed: {
    ...mapGetters(['isMemAuthenticated', 'currentMember','memberLoginToken']),
    is_renew_now_visible() {

      if (this.company.company_settings && this.company.company_settings.is_faith) {
        return false;
      }

      if (!this.member || !this.member.member_id) {
        return false;
      }

      if (this.member_renew !== null && this.member_renew !== undefined && this.member_renew) {
        return true;
      }

      const active_membership = this.memberships.filter(item => item.active === 1);

      // check if membership has expired or expired within a month
      for (const membership of active_membership) {
        if (dayjs(membership.vtdt).isBefore(dayjs().add(1, 'month'))) {
          return true;
        }
      }

      if (active_membership.length === 0) {
        return true;
      }

      return false;
    },

    has_any_address_fields_unlocked() {

      const address_fields = ['co','st','zi','pa','rc','cc','country',];

      for (const field of address_fields) {
        if (this.company.profile && this.company.profile[field]) {
          return true;
        }
      }

      return false;
    },

    has_any_invoice_fields_unlocked() {
      const invoice_fields = ['invoice_firstname','invoice_lastname','invoice_address','invoice_co','invoice_zipcode','invoice_post','invoice_country'];

      for (const field of invoice_fields) {
        if (this.company.profile && this.company.profile[field]) {
          return true;
        }
      }

      return false;
    }
  },

  methods: {
    async confirmed_request_exit() {
      try {
        const res = await axios.post(`/member/exit_request`);

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.EXIT_REQUESTED'));

          this.member.status = 'PENDING_EXIT';

          return;
        }
      }
      catch (err) {
        console.error('confirmed_request_exit error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.ERROR_REQUEST_EXIT'));
    },

    request_exit() {
      this.$refs['confirm_request_exit'].show();
    },

    file_created(file) {
      this.files.push(file);
    },
    
    memberships_created(memberships) {
      this.load_me();
    },

    async load_companies() {
      try {
        const res = await axios.get(`/company/publiclist`)

        if (res.status === 200) {
          this.companies = res.data;
        }
      }
      catch (err) {
        console.error('load_companies error', err);
      }
    },

    async renew_now_clicked() {
      try {
        // check if there is an active renewal

        if (this.member_renew === null) {
          this.member_renew = await this.get_member_renew();

          // if still null, create a renew
          if (this.member_renew === null) {
            this.member_renew = await this.create_renew();
          }
        }

        if (this.member_renew === null) {
          console.error('an error occured, member renew is null...');
          return;
        }

        location.href = '/x/' + this.member_renew.linkstr;
      }
      catch (err) {
        console.error('renew_now_clicked error', err);
      }
    },

    async load_me() {
      try {
        const res = await axios.get(`/member/me`);

        if (res.status === 200) {
          this.member = res.data.member;
          this.parents = res.data.parents;
          this.memberships = res.data.member.member_companies;
          this.company = res.data.company;
          this.invoices = res.data.member.invoices;
          this.instance_id = res.data.instance_id;
          this.qr_hash = this.member.qr_hash;

          console.log('qr hash', this.qr_hash);

          /*

          this.$store.dispatch(MEMBER_SET_COMPANY, { name: this.company.name, company_id: this.company.company_id });

          setTimeout(function() {
            messageParent(JSON.stringify({ height: document.body.scrollHeight }));
          }, 300);
          */
        }
      }
      catch (err) {
        console.error(err);
      }
    },

    family_member_created(family_member) {

      this.family_members.push(family_member);

    },

    family_member_updated(family_member) {

      const index = this.family_members.findIndex(item => item.member_id === family_member.member_id);

      if (index >= 0) {
        this.family_members[index] = family_member;

        this.family_members = [...this.family_members];
      }

    },


    async on_export_gdpr() {
      try {
        const res = await axios.get(`/member/mygdpr`);

        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        }
      }
      catch (err) {
        console.error(err);
      }

    },

    async load_files() {
      try {

        const res = await axios.get(`/file/member/list`);

        if (res.status === 200) {
          this.files = res.data;
        }

      }
      catch (err) {
        console.error(err);
      }

    },


    async save_data(data) {

      try {
        const res = await axios.put(`/member/public`, data);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBER.SAVED'));
        }
      }
      catch (err) {
        console.error(err);
      }

    },

    async load_events() {
      try {
        let baseUrl = axios.defaults.baseURL;
        this.baseUrl = baseUrl.substring(0, baseUrl.indexOf('/', 8));

        if (this.currentMember == null) return;

        const res = await axios.get(`/member_event/me`);

        if (res.status === 200) {
          this.events = res.data;
        }
      }
      catch (err) {
        console.error('load_events', err);
      }

    },

    async load_orders() {
      try {
        let baseUrl = axios.defaults.baseURL;
        this.baseUrl = baseUrl.substring(0, baseUrl.indexOf('/', 8));

        if (this.currentMember == null) return;

        const res = await axios.get(`/shop_order/my_orders`);

        if (res.status === 200) {
          this.orders = res.data;
        }
      }
      catch (err) {
        console.error('load_orders', err);
      }

    },


    async load_educations() {

      try {
        const res = await axios.get(`/education/me`);

        if (res.status !== 200) {
          console.error('unable to get educations', res.status);
          return;
        }

        this.educations = res.data.educations;
      }
      catch (err) {
        console.error(err);
        return;
      }

    },

    async get_member_renew() {
      try {
        const res = await axios.get(`/member_renew/me/active`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    async create_renew() {
      try {
        const res = await axios.post(`/member_renew/request`);

        if (res.status === 201) {
          return res.data;
        }
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    async load_member_renews() {

      try {
        this.member_renew = await this.get_member_renew();
      }
      catch (err) {
        console.error(err);
      }

    },

    async load_family_members() {

      try {
        const res = await axios.get(`/member_relation/member`);

        if (res.status !== 200) {
          console.error('unable to get family', res.status);
          return;
        }

        this.family_members = res.data;

        // remove ourselves from the relation
        this.family_members = this.family_members.filter(item => item.member_id !== this.member.member_id);
      }
      catch (err) {
        console.error(err);
        return;
      }

    },



  },

  mounted() {
    this.load_me();
    this.load_events();
    this.load_orders();
    this.load_educations();

    this.load_files();
    this.load_family_members();
    this.load_member_renews();
    this.load_companies();
  },

  data() {
    return {
      company: {},
      member: {},
      events: [],
      parents: [],
      memberships: [],
      orders: [],
      educations: [],
      invoices: [],
      companies: [],

      files: [],
      family_members: [],
      member_renew: null,
      instance_id: null,
      qr_hash: ''
    }
  }
};

</script>
