<template>
  <div class="container">
    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-certificate</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{$t('MEMBERSHIP.MEMBERSHIPS')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </b-col>
      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form">

      <b-table
        id="member-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="memberTable"
      >

      </b-table>

      <p v-if="items.length === 0">{{$t('MEMBERSHIP.NO_MEMBERSHIPS_FOUND')}}</p>


    </div>

    <hr/>
  </div>

</template>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberTableMemberships',

  props: ['member','items','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {},

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {
    async save_data() {

      this.$emit('save_data', this.member);

    }

  },

  mounted() {
    this.show_form = this.expanded;

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      fields: [
        {
          key: 'company.name',
          label: this.$t('COMPANY.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'reg_at',
          label: this.$t('MEMBERSHIP.REG_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'vtdt',
          label: this.$t('MEMBERSHIP.VTDT'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'is_paid',
          label: this.$t('MEMBERSHIP.IS_PAID'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.is_paid ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },
      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
